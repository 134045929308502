@media print {
  .no-print {
    display: none !important;
  }

  #navbar, #sidebar, #buttons-container, #new-product-button, .delete-product-button {
    display: none;
  }

  #app-content {
    margin: 0;
    padding: 0;
  }

  textarea[name="notes"] {
    height: auto !important;
  }

  .no-break {
    page-break-before: left;
    break-before: left;
    margin: 0;
    padding: 0;
    height: auto;
  }
}